import React, { Component } from 'react'
import classnaems from 'classnames'
import Icon from '../../components/icon/icon'
import {  withRouter } from 'react-router-dom'
import './card.scss'
interface Props {
  showRightArrow?: string,
  title: string,
  width?: number,
  height?: number
  icon?: string,
  jumpTo?: string
}
interface State {
  title: string,
  width: string,
  height: string,
  icon: string
  showRightArrow: string,
  jumpTo: string
}
class Card extends Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
      jumpTo: props.jumpTo || '',
      title: props.title,
      width: props.width + 'px' || '200px',
      height: props.height + 'px' || '200px',
      icon: props.icon || '',
      showRightArrow: props.showRightArrow || 'true'
    }
  }

  jumpTo(){
    //@ts-ignore
    let history:any =this.props.history
    if(this.state.jumpTo) {
      history.push(this.state.jumpTo)
    }
    return false
  }
  render() {
  
    return (
      <div className='card-content'
        style={{ width: this.state.width, height: this.state.height }}
        onClick={this.jumpTo.bind(this)}
      >
        <div className="card-title">
          <Icon size={15} url={this.state.icon} ></Icon>
          <div className="card-title-text">{this.props.title}</div>
          <div className={classnaems({
            'card-title-arrow': this.state.showRightArrow === 'false' ? false : true,
          })}></div>
        </div>
        {this.props.children}
      </div>
    )
  }
}
//@ts-ignore
export default  withRouter(Card)
