import axios from 'axios'
const service = axios.create({
  baseURL: 'https://api.a2.zs-tek.com',
  //baseURL: 'http://192.168.1.67:11043',
  withCredentials: true,
  timeout: 5000
})

// 请求拦截
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    let data: any = response.data
    return data
  },
  error => {

    if (error.request.status === 403) {

    }
    return Promise.reject(error)
  }
)

export default service