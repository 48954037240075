import React, { Component } from 'react'
import { Button } from 'antd-mobile'
import './live.scss'
import { withRouter } from 'react-router-dom'
class Live extends Component {

  jumpTo(path: string) {
    //@ts-ignore
    let history = this.props.history
    history.push(path)
  }

  render() {
    return (
      <div className='live-container'>
        <div className='btn-content qbdm-btn middle-btn'>
          <Button size='small' onClick={this.jumpTo.bind(this, '/singleStations/6')} style={{ width: '155px', marginTop: '20px', marginRight: '10px' }} type='primary'>
            产线
            </Button>
          <Button size='small' onClick={this.jumpTo.bind(this, '/singleStations/7')} style={{ width: '155px', marginTop: '20px' }} type='primary'>
            视觉品质检测
            </Button>
        </div>
        <div className='btn-content qbdm-btn middle-btn'>
          <Button size='small' onClick={this.jumpTo.bind(this, '/singleStations/1')} style={{ width: '100px', marginRight: '10px' }} type='primary'>
            工位1
            </Button>
          <Button size='small' onClick={this.jumpTo.bind(this, '/singleStations/2')} style={{ width: '100px', marginRight: '10px' }} type='primary'>
            工位2
            </Button>
          <Button size='small' onClick={this.jumpTo.bind(this, '/singleStations/3')} style={{ width: '100px' }} type='primary'>
            工位3
            </Button>
        </div>
        <div className='btn-content tjz-btn middle-btn'>
          <Button size='small' onClick={this.jumpTo.bind(this, '/singleStations/4')} type='primary' style={{ width: '100px', marginRight: '10px' }}>
            工位4
              </Button>
          <Button size='small' type='primary' style={{ width: '100px', marginRight: '10px' }} onClick={this.jumpTo.bind(this, '/singleStations/5')}>
            工位5
              </Button>
          <Button size='small' type='primary' style={{ width: '100px', visibility: 'hidden' }}>
            工位6
              </Button>
        </div>
        <div className='live-pic'></div>
      </div>
    )
  }
}

//@ts-ignore
export default withRouter(Live)