/**
 * 公共头部
 */
import React, { Component } from 'react'
import Icon from '../../components/icon/icon'
import { withRouter } from 'react-router-dom'

import './header.scss'
interface Props {
  name: string
}
interface State {
  name: string
}

class PublicHeader extends Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
      name: props.name
    }
  }
  goBackPage() {
   // @ts-ignore
   let history = this.props.history
    history.goBack()
  }
  goHomePage() {
    window.location.href='/'
  }
  render() {
    return (
      <div className='header-content content-bg'>
        <div className='back-btn' onClick={this.goBackPage.bind(this)}>
          <Icon size={16} url={require('../../assets/images/arrow.png')}></Icon>
        </div>

        <div className='page-name'>{this.state.name}</div>
        <div className='home-btn' onClick={this.goHomePage}>
          <Icon size={16} url={require('../../assets/images/home.png')}></Icon>
        </div>
      </div>
    )
  }
}
//@ts-ignore
export default withRouter(PublicHeader)