import React, { Component } from 'react'
import MiniCard from '../../components/miniCard/miniCard'
import PublicHeader from '../../components/header/header'
import TimePeriodSelector from '../../components/timePeriodSelector/timePeriodSelector'
import BlockBlank from '@/components/blockBlank/blockBlank'
import { Flex } from 'antd-mobile'
import Loading from '@/components/loading/loading'
import Card from '@/components/card/card'
import BasicLineChart from '@/components/basicLineChart/basicLineChart'
import Request from '@/api/request'
import API from '@/api/api'
import _ from 'lodash'
interface Props { }
interface State {
  miniCardGroup: Array<any>,
  isLoading: boolean,
  throughRateList: Array<any>
  defectList:Array<any>
}
export default class QualityPage extends Component<Props, State> {
  private getQualityDefectData: Request
  private getQualityThroughRateData: Request
  constructor(props: Props) {
    super(props)
    this.getQualityDefectData = new Request(API.GET_QUALITY_DEFECT_DATA)
    this.getQualityThroughRateData = new Request(API.GET_QUALITY_THROUGH_RATE_DATA)
    this.state = {
      defectList:[],
      throughRateList: [],
      isLoading: false,
      miniCardGroup: [{
        icon: require('../../assets/images/ztlv.png'),
        desc: '直通率',
        unit: '%',
        value: 0,
        size: 'lg'
      }, {
        icon: require('../../assets/images/lianpinglv.png'),
        desc: '良品数',
        unit: '双',
        value: 200
      }, {
        icon: require('../../assets/images/qx.png'),
        desc: '缺陷次数',
        unit: '次',
        value: 2
      }]
    }
  }
  changeTimePeriod(times: Array<any>, type: number) {
    this.setState({ isLoading: true })
    let params = { beginTime: times[0], endTime: times[1], timeRangeType: type }
    Promise.all([
      this.getQualityThroughRateData.send({ params }),
      this.getQualityDefectData.send({params})
    ]).then(res=>{
      let miniCardGroup = this.state.miniCardGroup
      let throughRateData:any = res[0]
      let defectData:any = res[1]
      let defectList = defectData.chartModels
      let throughRateList = throughRateData.chartModels
      miniCardGroup[0].value = _.round(throughRateData.throughRate,1)
      miniCardGroup[1].value = throughRateData.goodProductionCount
      miniCardGroup[2].value =defectData.defectCount
      this.setState({defectList,throughRateList, isLoading:false,miniCardGroup,})
    })
  }
  render() {
    let miniCardGroup = this.state.miniCardGroup.map((item: any, idx: number) =>
      <MiniCard unit={item.unit} value={item.value} desc={item.desc} icon={item.icon} key={idx} size={item.size} />
    )
    return (
      <div className='wrap'>
        <div className='page-content'>
          <PublicHeader name='质量管理'></PublicHeader>
          <TimePeriodSelector changeTimePeriod={this.changeTimePeriod.bind(this)}></TimePeriodSelector>
          {
            this.state.isLoading ? <Loading /> :
              <BlockBlank>
                <Flex wrap='wrap' justify='between'>
                  {miniCardGroup}
                </Flex>
                <Card icon={require('../../assets/images/ztl2.png')} title='直通率(%)' width={350} showRightArrow='false'>
                  <BasicLineChart seriesName='直通率' data={this.state.throughRateList} id='through-rate-chart'/>
                </Card>
                <Card icon={require('../../assets/images/qx2.png')} title='缺陷(次)' width={350}  showRightArrow='false'>
                  <BasicLineChart seriesName='缺陷次数' data={this.state.defectList} id='defect-chart' lineColor='#FC9800' areaStyle1={'rgba(252, 151, 0, 0.775)'} areaStyle2={'rgba(252, 151, 0, 0.08)'}/>
                </Card>
              </BlockBlank>
          }
        </div>
      </div>
    )
  }
}