import React, { Component } from 'react'
import { Button, Flex } from 'antd-mobile';
import './notFound.scss'
import BlockBlank from '../blockBlank/blockBlank';
import { withRouter } from 'react-router-dom'
interface Props { }
interface State { }

class NotFound extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className='not-found-box'>
        <div className='not-found-content'></div>
        <p>不存在的页面</p>
        <BlockBlank >
          <Flex justify='center'>
            <Button type="primary" style={{ 'width': '40rem', 'marginRight': '3rem' }} onClick={()=>{
            }}> 返回</Button>
            <Button type="primary" style={{ 'width': '40rem' }}>首页</Button>
          </Flex>
        </BlockBlank>
      </div>
    )
  }
}
//@ts-ignore
export default withRouter(NotFound)