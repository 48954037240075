import React, { Component, Fragment } from 'react'
import PublicHeader from '../../components/header/header'
import TimePeriodSelector from '@/components/timePeriodSelector/timePeriodSelector'
import { Flex } from 'antd-mobile'
import MiniCard from '@/components/miniCard/miniCard'
import BlockBlank from '@/components/blockBlank/blockBlank'
import Card from '@/components/card/card'
import BasicLineChart from '@/components/basicLineChart/basicLineChart'
import DetailsHeapBarChart from '@/components/detailsHeapBarChart/DetailsHeapBarChart'
import BlockTitle from '@/components/blockTitle/blockTitle'
import CellGroup from '@/components/cellGroup/cellGroup'
// import StationEquipmentStatus from './stationEquipmentStatus/stationEquipmentStatus'
import API from '@/api/api'
import Request from '@/api/request'
import Loading from '@/components/loading/loading'
import _ from 'lodash'

// const tabs = [
//   { title: '前帮打磨', sub: '1' },
//   { title: '左脚涂胶', sub: '2' },
//   { title: '左脚涂处理剂', sub: '3' },
//   { title: '右脚涂胶', sub: '4' },
//   { title: '右脚涂处理剂', sub: '5' },
// ];
interface Props { }
interface State {
  isLoading: boolean,
  pageLoading: boolean,
  miniCardGroup: Array<any>,
  systemStatusList: Array<any>,
  energyChart: Array<any>,
  utilizationChart: Array<any>,
  equipmentUtilizationDataList: Array<any>,
  equipmenthourUsages: Array<any>,
  historyWorkTimeData: Array<any>,
  throughStandby: any,
  choiceNum: Number
}
export default class EquipmentPage extends Component<Props, State> {
  private gtEquipmentSystemStateData: Request
  private getEquipmentEnergyData: Request
  private getEquipmentUtilizationData: Request
  private readonly productionCardTypeToDesc: any = {
    0: '今日能耗',
    1: '本周能耗',
    2: '本月能耗',
    3: '今年能耗',
    4: '今年能耗',
    5: '今年能耗',
    6: '累积能耗'
  }
  constructor(props: Props) {
    super(props)
    this.gtEquipmentSystemStateData = new Request(API.GET_EQUIPMENT_SYSTEM_STATE_DATE)
    this.getEquipmentEnergyData = new Request(API.GET_EQUIPMENT_ENERGY_DATA)
    this.getEquipmentUtilizationData = new Request(API.GET_EQUIPMENT_UTILIZATION_DATA)
    this.state = {
      pageLoading: true,
      utilizationChart: [],
      energyChart: [],
      isLoading: true,
      systemStatusList: [
        {
          icon: require('../../assets/images/robot1.png'),
          errorIcon: require('../../assets/images/robot.png'),
          title: '开机时长',
          status: '--',
          error: false,
        }, {
          icon: require('../../assets/images/daiji1.png'),
          errorIcon: require('../../assets/images/daiji2.png'),
          title: '待机时长',
          status: '--',
          error: false,
        },
        {
          icon: require('../../assets/images/gl2.png'),
          errorIcon: '1',
          title: '功率',
          status: '--',
          error: false,
        }
      ],
      miniCardGroup: [
        {
          desc: '稼动率',
          unit: '%',
          icon: require('../../assets/images/jiadonglv1.png'),
          value: 0,
          size: 'lg'
        }, {
          desc: '累积能耗',
          unit: '度',
          icon: require('../../assets/images/nenghao1.png'),
          value: 0
        },
        {
          desc: '能源利用率',
          unit: '双/度',
          icon: require('../../assets/images/nengyuan.png'),
          value: 0
        }
      ],
      equipmentUtilizationDataList: [],
      equipmenthourUsages: [],
      historyWorkTimeData: [],
      throughStandby: {
        dataX: [],
        dataY1: [],
        dataY2: []
      },
      choiceNum: 0
    }
  }




  componentDidMount() {
    this.gtEquipmentSystemStateData.send().then(response => {
      let res: any = response
      console.log(res);
      let systemStatusList = this.state.systemStatusList
      systemStatusList[0].status = this.handleDuration(res.utilizationTimeMinutes)
      systemStatusList[1].status = this.handleDuration(res.pauseTimeMinutes)
      systemStatusList[2].status = { value2: res.power, unit2: ' kw' }
      // res.power + ' kw'
      this.setState({ systemStatusList, pageLoading: false })
    })

  }
  handleDuration(duration: number) {
    if (duration >= 60) {
      return { value1: Math.floor(duration / 60), unit1: ' 小时', value2: (duration % 60), unit2: ' 分' }
      // `${Math.floor(duration / 60)}小时 ${duration % 60}分 `
    }
    return { value2: duration, unit2: ' 分钟' }
    // duration + ' 分钟'
  }
  changeTimePeriod(times: Array<string>, type: number) {
    this.setState({ isLoading: true })
    let params = { beginTime: times[0], endTime: times[1], timeRangeType: type }
    Promise.all([
      this.getEquipmentEnergyData.send({ params }),
      this.getEquipmentUtilizationData.send({ params }),
      new Request(API.GET_HISTORY_WORK_TIME_DATA + '' + type).send()
    ]).then(response => {
      // console.log(response)
      let miniCardGroup = this.state.miniCardGroup
      let equipmentEnergyData: any = response[0]
      let equipmentUtilizationData: any = response[1]
      let historyWorkTimeData: any = response[2]
      let handlePairEnergy: any = (1 / equipmentEnergyData.pairEnergy) === Infinity ? '0' : (1 / equipmentEnergyData.pairEnergy)
      miniCardGroup[0].value = equipmentUtilizationData.utilizationTimeMinutes / (equipmentUtilizationData.utilizationTimeMinutes + equipmentUtilizationData.pauseTimeMinutes) * 100 || '0'
      miniCardGroup[1].value = equipmentEnergyData.totalEnergy
      miniCardGroup[2].value = handlePairEnergy
      miniCardGroup[1].desc = this.productionCardTypeToDesc[type]
      this.setState({
        utilizationChart: equipmentUtilizationData.chartModels.map((item: any) => { return { key: item.key, value: _.round(item.value) } }),
        energyChart: equipmentEnergyData.chartModels,
        miniCardGroup,
        equipmentUtilizationDataList: equipmentUtilizationData.chartModels,
        equipmenthourUsages: equipmentUtilizationData.hourUsages,
        historyWorkTimeData,
        choiceNum: type,
        isLoading: false,
      }, () => {
        this.setState({
          throughStandby: this.handleChartsData1()
        })
      })
    })
  }

  handleChartsData1() {
    let data: any = {
      dataX: [],
      dataY1: [],
      dataY2: []
    };
    if (this.state.equipmenthourUsages) {
      // console.log(this.state.equipmenthourUsages)
      this.state.equipmenthourUsages.map((item: any) => {
        data.dataX.push(item.timeRangeName);
        data.dataY1.push(item.utilizationHours.toFixed(1));
        data.dataY2.push(item.pauseHours.toFixed(1));
        return 0;
      })
    }
    return data
  }

  render() {
    let miniCardGroup = this.state.miniCardGroup.map((item: any, idx: number) => <MiniCard unit={item.unit} value={item.value} desc={item.desc} icon={item.icon} size={item.size} key={idx} />)
    return (
      <div className='wrap'>
        <div className='page-content'>
          <PublicHeader name='设备管理' />

          {
            this.state.pageLoading ? <Loading /> :
              <Fragment>
                <BlockBlank>
                  <BlockTitle name='系统状态' />
                  <CellGroup list={this.state.systemStatusList} />
                  {/* <BlockTitle name='工位设备状态' /> */}
                  {/* <StationEquipmentStatus></StationEquipmentStatus> */}
                  {/* <Tabs
            renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}
              tabBarBackgroundColor='#000'
              tabBarUnderlineStyle=''
              tabs={tabs}
              initialPage={1}
              onChange={(tab, index) => { console.log('onChange', index, tab); }}
              onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
            >
              <CellGroup list={this.state.systemStatusList} />
              <CellGroup list={this.state.systemStatusList} />
              <CellGroup list={this.state.systemStatusList} />
            </Tabs> */}

                </BlockBlank>

                <TimePeriodSelector changeTimePeriod={this.changeTimePeriod.bind(this)}></TimePeriodSelector>
                {
                  this.state.isLoading ? <Loading /> :
                    <BlockBlank>
                      <Flex wrap='wrap' justify='between'>
                        {miniCardGroup}
                      </Flex>
                      <Card title='稼动时长(时)/待机时长(时)' icon={require('../../assets/images/cl2.png')} width={350} showRightArrow='false'>
                        <DetailsHeapBarChart
                          theid='throughStandby-chart'
                          markLineData={[
                            {
                              yAxis: this.state.choiceNum === 1 || this.state.choiceNum === 2 ? '8' : '-999',
                              name: 'worktime'
                            },
                          ]}
                          size={{ width: 350, height: 180 }}
                          dataX={this.state.throughStandby.dataX}
                          dataY1={this.state.throughStandby.dataY1}
                          dataY2={this.state.throughStandby.dataY2}
                          boundaryGap={this.state.choiceNum === 1 ? ["0", "2"] : ''}
                        >
                        </DetailsHeapBarChart>
                      </Card>
                      <Card title='稼动率(%)' icon={require('../../assets/images/jiadonglv2.png')} width={350} showRightArrow='false'>
                        <BasicLineChart seriesName='稼动率' data={this.state.utilizationChart} id='utilization-cahrt' />
                      </Card>
                      <Card title='能耗(度)' icon={require('../../assets/images/nenghao2.png')} width={350} showRightArrow='false'>
                        <BasicLineChart seriesName='能耗' data={this.state.energyChart} id='energy-chart' />
                      </Card>
                    </BlockBlank>
                }
              </Fragment>
          }
        </div>
      </div>
    )
  }
}