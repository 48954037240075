let API = {
  /**获取环境参数 */
  GET_ENVIRONMENT: '/BigExhibition/BigExhibition/GetEnvironment',
  /**获取今日加工数据 */
  GET_TODAY_PROCESS_DATA: '/BigExhibition/BigExhibition/GetToDayProcessData',
  /**获取本周加工数据 */
  GET_WEEK_PROCESS_YIELD: '/BigExhibition/BigExhibition/GetHistoryProcessData/1',
  /**获取产线累计产量*/
  GET_All_PROCESS_DATA: '/BigExhibition/BigExhibition/GetAllProcessData',
  /**根据天数获取历史加工数据 */
  GET_HISTORY_PROCESS_BY_DAY_COUNT: '/BigExhibition/BigExhibition/GetHistoryProcessDayCountData/15',
  /**获取历史加工数量 */
  GET_HISTORY_PROCESS_DATA: '/BigExhibition/BigExhibition/GetHistoryProcessData/',
  /**获取产品生产数量 */
  GET_PRODUCTION_COUNT_DATA: '/Production/Production/GetProductionCountData',
  /**获取稼动数据 */
  GET_PRODUCTION_BEAT_DATA: '/Production/Production/GetProductionBeatData',
  /**获取鞋型产量数据 */
  GET_PRODUCTION_SHOE_TYPE_DATA: '/Production/Production/GetProductionShoeTypeData',
  /**获取实时环境数据 */
  GET_ENVIRONMENT_DATA: '/BigExhibition/BigExhibition/GetEnvironment',
  /**获取历史环境数据 */
  GET_HISTORY_ENVIRONMENT_DATA: '/BigExhibition/BigExhibition/GetHistoryEnvironmentData/',
  /**获取直通率 */
  GET_QUALITY_THROUGH_RATE_DATA: '/Quality/Quality/GetQualityThroughRateData',
  /**获取缺陷次数 */
  GET_QUALITY_DEFECT_DATA: '/Quality/Quality/GetQualityDefectData',

  /**获取系统设备状态 */
  GET_EQUIPMENT_SYSTEM_STATE_DATE: '/Equipment/Equipment/GetEquipmentSystemStateData',
  /**获取能耗 */
  GET_EQUIPMENT_ENERGY_DATA: '/Equipment/Equipment/GetEquipmentEnergyData',
  /**获取设备稼动数据 */
  GET_EQUIPMENT_UTILIZATION_DATA: '/Equipment/Equipment/GetEquipmentUtilizationData',
  /**获取单工位质量数据 */
  GET_PRODUCTION_THROUGH_DATA: '/WorkStation/WorkStation/GetProductionThroughData',
  /**获取2d检测信息 */
  GET_2D_TESTING:'/WorkStation/WorkStation/Get2DTesting_InformationData/',

  /**获取历史上班时长*/
  GET_HISTORY_WORK_TIME_DATA: '/BigExhibition/BigExhibition/GetHistoryWorkTimeData/',

}
export default API