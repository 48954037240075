import React from "react";
//@ts-ignore
import EZUIKit from 'ezuikit-js';
import Request from '@/api/request';
import QS from 'qs';

interface Props {
    data: any
}

interface State {
    accessToken: string
}

class UseUikit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            accessToken: '',
        }
    }

    componentDidMount() {
        new Request('/api/lapp/token/get', 'post').send({
            data: QS.stringify({
                appKey: '305ce83133e3474ea4031d0589f4ae54', appSecret: 'b4713e0627f19beb6db74fecd0b33940'
            }),
            baseURL: 'https://open.ys7.com',
        }).then((res: any) => {
            // console.log('res', res);
            this.setState({ accessToken: res.data.accessToken }, () => {
                let player = new EZUIKit.EZUIKitPlayer({
                    id: 'video-container', // 视频容器ID
                    accessToken: this.state.accessToken,
                    url: this.props.data.url,
                    audio: 0,
                })
                player.play();
            })
        })
    }

    render() {
        return (
            <div id="video-container" style={{ width: '350px', height: '270px' }}></div>
        )
    }
}

export default UseUikit;

// export default function UseUikit() {

//     window.onload = function () {
//         let player = new EZUIKit.EZUIKitPlayer({
//             id: 'video-container', // 视频容器ID
//             accessToken: 'at.cqd89amu7rduq7nec1h9fb3pa2hzueeq-41wqou8o1p-00pgj87-r9afmszmh',
//             url: 'ezopen://open.ys7.com/E45418124/1.live',
//         })

//         player.play();
//     }

//     return (
//         <div id="video-container" style={{ width: '800px', height: '500px' }}></div>
//     )
// }