import React, { Component } from 'react'
import MiniCard from '@/components/miniCard/miniCard'
import { Flex } from 'antd-mobile'
import PublicHeader from '@/components/header/header'
import BlockBlank from '@/components/blockBlank/blockBlank'
import Card from '@/components/card/card'
import BasicLineChart from '@/components/basicLineChart/basicLineChart'
import Request from '@/api/request'
import API from '@/api/api'
import Loading from '@/components/loading/loading'

interface Props { }
interface State {
  miniCardGroup: Array<any>,
  isLoading: Boolean,
  temperatureList: Array<any>,
  noiseList: Array<any>,
  pM25List: Array<any>,
  humidnessList: Array<any>

}
export default class EnvironmentPage extends Component<Props, State> {
  private getHistoryEnvironment: Request
  private getEnvironment: Request
  constructor(props: Props) {
    super(props)
    this.getHistoryEnvironment = new Request(API.GET_HISTORY_ENVIRONMENT_DATA)
    this.getEnvironment = new Request(API.GET_ENVIRONMENT_DATA)
    this.state = {
      temperatureList: [],
      noiseList: [],
      pM25List: [],
      humidnessList: [],
      isLoading: false,
      miniCardGroup: [
        {
          unit: '℃',
          value: 1,
          desc: '温度',
          icon: require('../../assets/images/wd1.png')
        },
        {
          unit: '%',
          value: 2,
          desc: '湿度',
          icon: require('../../assets/images/sd1.png')
        },
        {
          unit: 'dB',
          value: 3,
          desc: '噪音',
          icon: require('../../assets/images/zy1.png')
        },
        {
          unit: 'μg/m3',
          value: 4,
          desc: 'PM2.5',
          icon: require('../../assets/images/PM2.5_1.png')
        }
      ]
    }
  }

  //获取实时数据
  getRealtimeEnvironment() {
    this.getEnvironment.send().then(response => {
      let miniCardGroup = this.state.miniCardGroup
      let res: any = response
      miniCardGroup[0].value = Math.floor(res.temperature)
      miniCardGroup[1].value = Math.floor(res.humidness * 100)
      miniCardGroup[2].value = Math.floor(res.noise)
      miniCardGroup[3].value = Math.floor(res.pM25)
      this.setState({ miniCardGroup })
    })
  }
  //获取历史数据
  getHistoryEnviroment() {
    this.getHistoryEnvironment.send({ options: '0' }).then(response => {
      let res: any = response
      let temperatureList: Array<any> = []
      let noiseList: Array<any> = []
      let pM25List: Array<any> = []
      let humidnessList: Array<any> = []
      res.forEach((item: any) => {
        if (item.value) {
          temperatureList.push({
            key: item.key,
            value: item.value.temperature
          })
          noiseList.push({
            key: item.key,
            value: item.value.noise
          })
          pM25List.push({
            key: item.key,
            value: item.value.pM25
          })
          humidnessList.push({
            key: item.key,
            value: item.value.humidness * 100
          })
        }
      })

      this.setState({
        temperatureList, noiseList, pM25List, humidnessList, isLoading: false
      })
    })
  }
  componentDidMount() {
    this.setState({ isLoading: true })
    this.getRealtimeEnvironment()
    this.getHistoryEnviroment()
  }
  render() {
    let miniCardGroup = this.state.miniCardGroup.map((item: any, idx) =>
      <MiniCard unit={item.unit} value={item.value} desc={item.desc} icon={item.icon} key={idx} />)
    return (
      <div className='wrap'>
        <div className='page-content'>
          <PublicHeader name='环境管理' />
          {
            this.state.isLoading ? <Loading /> :
              <BlockBlank top={10}>
                <Flex wrap='wrap' justify='between' >{miniCardGroup}</Flex>
                <Card title='温度(℃)' width={350} showRightArrow='false' icon={require('@/assets/images/wd2.png')}>
                  <BasicLineChart seriesName='温度' data={this.state.temperatureList} id='temperature-chart' maxData='40'
                    markLineData={[
                      {
                        yAxis: 30,
                        name: 'top'
                      },
                      {
                        yAxis: 15,
                        name: 'bottom'
                      },
                    ]}
                  />
                </Card>
                <Card title='湿度(%)' width={350} showRightArrow='false' icon={require('@/assets/images/sd2.png')}>
                  <BasicLineChart seriesName='湿度' data={this.state.humidnessList} id='humidness-chart' maxData='100'
                    markLineData={[
                      {
                        yAxis: 60,
                        name: 'top'
                      },
                      {
                        yAxis: 30,
                        name: 'bottom'
                      },
                    ]}
                  />
                </Card>
                <Card title='噪音(dB)' width={350} showRightArrow='false' icon={require('@/assets/images/zy2.png')}>
                  <BasicLineChart seriesName='噪音' data={this.state.noiseList} id='noise-chart' maxData='100'
                    markLineData={[
                      {
                        yAxis: 65,
                        name: 'bottom'
                      },
                    ]}
                  />
                </Card>
                <Card title='PM2.5(μg/m3)' width={350} showRightArrow='false' icon={require('@/assets/images/PM2.5_2.png')}>
                  <BasicLineChart seriesName='PM2.5' data={this.state.pM25List} id='pm25-chart' maxData='100'
                    markLineData={[
                      {
                        yAxis: 70,
                        name: 'bottom'
                      },
                    ]}
                  />
                </Card>
              </BlockBlank>
          }
        </div>
      </div>
    )
  }
}