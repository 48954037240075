import React, { Component, Fragment } from 'react'
import PublicHeader from '../../components/header/header'
import TimePeriodSelector from '../../components/timePeriodSelector/timePeriodSelector'
import MiniCard from '../../components/miniCard/miniCard'
import { Flex } from 'antd-mobile'
import BlockBlank from '../../components/blockBlank/blockBlank'
import Card from '../../components/card/card'
import Request from '../../api/request'
import API from '../../api/api'
import Loading from '../../components/loading/loading'
import ShoeTypeChart from './shoeTypeChart'
import BasicLineChart from '@/components/basicLineChart/basicLineChart'
import _ from 'lodash'
interface Props {

}
interface State {
  miniCardGroup: Array<any>,
  isLoading: Boolean
  productionChart: Array<any>,
  taktChart: Array<any>,
  shoeTypeChart: Array<any>
}
export default class ProductionPage extends Component<Props, State> {
  private getProductionCountData: Request
  private getProductionBeatData: Request
  private getProductionShoeTypeData: Request
  private readonly productionCardTypeToDesc: any = {
    0: '今日产量',
    1: '本周产量',
    2: '本月产量',
    3: '今年产量',
    4: '今年产量',
    5: '今年产量',
    6: '累积产量'
  }
  constructor(props: Props) {
    super(props)
    this.getProductionCountData = new Request(API.GET_PRODUCTION_COUNT_DATA)
    this.getProductionBeatData = new Request(API.GET_PRODUCTION_BEAT_DATA)
    this.getProductionShoeTypeData = new Request(API.GET_PRODUCTION_SHOE_TYPE_DATA)
    this.state = {
      shoeTypeChart: [],
      taktChart: [],
      productionChart: [],
      isLoading: false,
      miniCardGroup: [{
        unit: '双',
        desc: '今日产量',
        icon: require('../../assets/images/cl.png'),
        value: ''
      }, {
        unit: '秒/双',
        desc: '生产节拍',
        icon: require('../../assets/images/jp.png'),
        value: '0'
      }, {
        unit: '双/小时',
        desc: '平均产能',
        icon: require('../../assets/images/pj.png'),
        value: '1'
      }, {
        unit: '款',
        desc: '上线鞋型',
        icon: require('../../assets/images/ggx3.png'),
        value: '0'
      }]
    }
  }

  /**
   * 改变日期的时候触发
   * @param type 
   */
  changeTimePeriod(times: Array<any>, type: number) {
    this.setState({ isLoading: true })
    let params = { beginTime: times[0], endTime: times[1], timeRangeType: type }
    Promise.all([
      this.getProductionCountData.send({ params }),
      this.getProductionBeatData.send({ params }),
      this.getProductionShoeTypeData.send({ params })
    ]).then(res => {
      let productionCountData: any = res[0]
      let productionBeatData: any = res[1]
      let productionShoeTypeData: any = res[2]
      let miniCardGroup = this.state.miniCardGroup
      miniCardGroup[0].desc = this.productionCardTypeToDesc[type]
      miniCardGroup[0].value = productionCountData.productionCount
      miniCardGroup[1].value = _.round(productionBeatData.aPairOfSeconds, 1)
      miniCardGroup[2].value = Math.floor(productionBeatData.hourAFewPairs)
      miniCardGroup[3].value = productionShoeTypeData.shoeTypeCount
      this.setState({
        isLoading: false,
        miniCardGroup,
        productionChart: productionCountData.chartModels,
        taktChart: productionBeatData.chartModels.map((item: any) => { return { key: item.key, value: item.value === 0 ? 0 : _.round((3600 / item.value), 1) } }),
        shoeTypeChart: productionShoeTypeData.chartModels
      })
    })
  }

  formatDecimal(num: string, decimal: number) {
    num = num.toString()
    let index = num.indexOf('.')
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1)
    } else {
      num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
  }

  render() {
    let miniCardGroup = this.state.miniCardGroup.map((item, idx) =>
      <MiniCard unit={item.unit} value={item.value} desc={item.desc} icon={item.icon} key={idx} />)
    return (
      <div className='wrap'>
        <div className='page-content'>
          <PublicHeader name='生产管理' />
          <TimePeriodSelector changeTimePeriod={this.changeTimePeriod.bind(this)} />
          <BlockBlank>
            {
              this.state.isLoading ?
                <Loading /> :
                <Fragment>
                  <Flex wrap='wrap' justify='between'> {miniCardGroup} </Flex>
                  <Card title='产量(双)' icon={require('../../assets/images/cl2.png')} width={350} showRightArrow='false'>
                    <BasicLineChart data={this.state.productionChart} id='production-chart' seriesName='产量' />
                  </Card>
                  <Card title='平均产能(双/小时)' icon={require('../../assets/images/jpd.png')} width={350} showRightArrow='false'>
                    <BasicLineChart data={this.state.taktChart} id='takt-chart' seriesName='平均产能' />
                  </Card>
                  <Card title='柔性化分析' icon={require('../../assets/images/ggx.png')} width={350} showRightArrow='false'>
                    <ShoeTypeChart data={this.state.shoeTypeChart} />
                  </Card>
                </Fragment>
            }
          </BlockBlank>
        </div>
      </div>
    )
  }
}