/**
 * 公共底部
 */
import React,{Component} from 'react'
import './footer.scss'




class PublicFooter extends Component{

  render() {
    return(
      <div className="foot-content">
        {/* <div>服务热线：0571-50901067</div>
        <div>服务邮箱：zs-tek@zs-tek.com</div>
        <div>官网地址：<a href="https://www.zs-tek.com/#/">www.zs-tek.com</a></div>
        <div style={{fontSize:'12px',color:'#999999'}}>Copyright @2020 知守科技（杭州）有限公司 </div>
        <div></div> */}
      </div>
      ) 
  }
}

export default PublicFooter