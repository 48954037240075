import React, { Component } from 'react'
import './headMessage.scss'
// import Icon from '../../../components/icon'
import Request from '../../../api/request';
import API from '../../../api/api';
interface Props {
  changeLoadingProgress?: Function
}
interface State {
  workRate: 0,
  todayYield: 0,
  goodProcessCount: 0,
  inferiorProocessCount: 0,
  yieldRate: 0,
  runningTimeHour: 0,
  allProcessData: 0
}
export default class HeadMessage extends Component<Props,State> {
  private getTodayProcessData: Request
  private getAllProcessData: Request
  constructor(props: Props) {
    super(props);
    this.state = {
      workRate: 0,
      todayYield: 0,
      goodProcessCount: 0,
      inferiorProocessCount: 0,
      yieldRate: 0,
      runningTimeHour: 0,
      allProcessData: 0
    }
    this.getTodayProcessData = new Request(API.GET_TODAY_PROCESS_DATA)
    this.getAllProcessData = new Request(API.GET_All_PROCESS_DATA)
  }

  private getComponentData: Function = () => {
    this.getAllProcessData.send().then(res => {
      let data: any = res
      this.setState({
        allProcessData: data.processCount
      })
    })

    this.getTodayProcessData.send().then(res => {
      let data: any = res
      this.setState({
        workRate: data.workRate,
        todayYield: data.todayYield,
        goodProcessCount: data.goodProcessCount,
        inferiorProocessCount: data.inferiorProocessCount,
        yieldRate: data.yieldRate,
        runningTimeHour: data.runningTimeHour
      })
    })
  }
  componentDidMount() {
    this.getComponentData()
    setInterval(() => { this.getComponentData() }, 1000 * 60)
  }
  render() {
    return (
      <div className='head-message-content content-bg'>
        {/* <Icon size={7} url={require('../../../assets/images/message.png')} />  */}
        <div >
          <div className="message-item">
            <div className="message-name">累积产量</div>
            <div className="message-val"><span className="card-num">{this.state.allProcessData.toLocaleString()}</span> 双</div>
          </div>

          {/* <div className="message-item">
            <div className="message-name">今日产量</div>
            <div className="message-val">{this.state.goodProcessCount} 双</div>
          </div> */}
          {/* <Carousel className="message-carousel"
            vertical
            dots={false}
            autoplay
            infinite
            autoplayInterval={2000}
          >
            <div className="message-item">
              <div className="message-name">良品加工数</div>
              <div className="message-val">{this.state.goodProcessCount}双</div>
            </div>

            <div className="message-item">
              <div className="message-name">累积产量</div>
              <div className="message-val">{this.state.allProcessData}双</div>
            </div>
            <div className="message-item">
              <div className="message-name">今日产量</div>
              <div className="message-val">{this.state.goodProcessCount + this.state.inferiorProocessCount}双</div>
            </div>

            <div className="message-item">
              <div className="message-name">稼动率</div>
              <div className="message-val">{this.state.workRate}%</div>
            </div>

            <div className="message-item">
              <div className="message-name">良品加工数</div>
              <div className="message-val">{this.state.goodProcessCount}双</div>
            </div>

            <div className="message-item">
              <div className="message-name">运行时长</div>
              <div className="message-val">{this.state.runningTimeHour}小时</div>
            </div>
          </Carousel> */}

        </div>
      </div>
    )
  }
}