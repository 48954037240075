/**
 * 首页
 */
import React, { Component, Fragment } from 'react'
import PublicHeader from '../../components/header/header'
import Card from '../../components/card/card'
import HeadMessage from './headMessage/headMessage'
import Environ from './environ/environ'
import Production from './production/production'
import Quality from './quality/quality'
import Equipemt from './equipment/equipment'
import Live from './live/live'
import Footer from '../../components/footer/footer'
import './home.scss'
// import Loading from '@/components/loading/loading'
export default class Home extends Component {
  state = {
    isLoading: true,
    requestCount: 0,
    progress: Math.floor((Math.random() * 30))
  }

  render() {
    return (
      <div className="wrap" >
        <div className='page-content'>
          {/* 头部 */}
          <PublicHeader name='ZS-TEK智能化产线' />
          {/* 消息轮播 */}
          {
            <Fragment>
              <HeadMessage />
              {/* 几个模块 */}
              <div className="module-content">
             
            

                <Card title='生产' icon={require('../../assets/images/produce.png')} jumpTo='/production' width={170} height={190}> 
                   <Production />
                </Card>
                <Card title='质量' icon={require('../../assets/images/quality.png')} jumpTo='/quality' width={170} height={190} >
                  <Quality />
                </Card>

                <Card title='设备' icon={require('../../assets/images/equipment.png')} jumpTo='/equipment' width={170}  height={190}>
                  <Equipemt />
                </Card>

                <Card title='环境' icon={require('../../assets/images/env.png')} jumpTo='/environment' width={170} height={190}>
                  <Environ />
                </Card>
              </div>

              {/* 加工实况 */}
              <div className="live-content">
                <Card title='实况' icon={require('../../assets/images/live.png')} width={350} showRightArrow='false'>
                  <Live />
                </Card>
              </div>

              <Footer></Footer>
            </Fragment>
          }
        </div>
      </div>
    )
  }
}