import React, { Component } from 'react'
import './environ.scss'
import Request from '../../../api/request'
import API from '../../../api/api'
import classnames from 'classnames'
interface Props{
  changeLoadingProgress?:Function
}
interface State {
  temperature: 0,
  noise: 0,
  pM25: 0,
  humidness: 0
}
export default class Environ extends Component<Props,State> {
  private getEnvironData:Request
    constructor(props:Props){
      super(props);
      this.state = {
        temperature: 0,
        noise: 0,
        pM25: 0,
        humidness: 0
      }
      this.getEnvironData = new Request(API.GET_ENVIRONMENT)
    }

  componentDidMount() {
    let setEnvironData = () => {
      this.getEnvironData.send().then(res => {
        let data =res as any
        this.setState({
          temperature: data.temperature.toFixed(0),
          noise: data.noise.toFixed(0),
          pM25: data.pM25.toFixed(0),
          humidness: data.humidness
        })
      })
    }

    this.getEnvironData.send().then(res => {
      let data =res as any
      this.setState({
        temperature: data.temperature.toFixed(0),
        noise: data.noise.toFixed(0),
        pM25: data.pM25.toFixed(0),
        humidness: data.humidness
      },()=>{
        // this.props.changeLoadingProgress()
      })
    })
    setInterval(() => { setEnvironData() }, 1000 * 30)
  }
  render() {
    return (
      <div className='environ-content'>
        <div className="environ-item">
          <div className='environ-title'>温度</div>
          <div className={classnames({
          'environ-value':true,
          'normal-color':true,
          'worring-color':this.state.temperature>30? true:false
          })} > 
     
            <div className='environ-value-num' >{this.state.temperature}</div>
            <div className='environ-value-util'>&nbsp;℃</div>
          </div>
        </div>
        <div className="environ-item">
          <div className='environ-title'>湿度</div>
          <div className={classnames({
            'environ-value':true,
            'normal-color':true,
            'worring-color':this.state.humidness<0.3 || this.state.humidness>0.6
          })}>
            <div className='environ-value-num'>{(this.state.humidness*100).toFixed(0)}</div>
            <div className='environ-value-util'>&nbsp;%</div>
          </div>
        </div>
        <div className="environ-item">
          <div className='environ-title'>噪音</div>
          <div  className={classnames({
            'environ-value':true,
            'normal-color':true,
            'worring-color':this.state.noise>65?true:false
          })}>
            <div className='environ-value-num'>{this.state.noise}</div>
            <div className='environ-value-util'>&nbsp;dB</div>
          </div>
        </div>
        <div className="environ-item">
          <div className='environ-title'>PM2.5</div>
          <div  className={classnames({
                'environ-value':true,
                'normal-color':true,
                'worring-color': this.state.pM25>70 ? true:false

          })}>
            <div className='environ-value-num'>{this.state.pM25}</div>
            <div className='environ-value-util'>&nbsp;μg/m³</div>
          </div>
        </div>
      </div>
    )
  }
}