import React, { Component } from 'react'
import echarts from 'echarts';
import ChartContainer from '../../../components/chartContainer/chartContainer'
import 'echarts-liquidfill'
import Request from '../../../api/request';
import API from '../../../api/api';
interface Props {
    changeLoadingProgress?: Function
}
export default class Equipment extends Component<Props>{
    state = {
        workRate: 0
    }
    componentDidMount() {


        let getChartOption =  (workRate: number)=> {
            let value = this.state.workRate;
            let color = new echarts.graphic.LinearGradient(
                0, 0, 1, 0, [{
                        offset: 0,
                        color: "#41D7F3",
                    },
                    {
                        offset: 1,
                        color: "#3D9FFF",
                    }
                ]
            );
           let option = {
                axisTick:{
                    show:false
                },
                series: [{
                        name: '',
                        type: 'gauge',
                        startAngle: 225,
                        endAngle: -45,
                        min: 0,
                        max: 100,
                        radius: '2%',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: [
                                    [1, '#999']
                                ],
                                width: 1,
                                opacity: 1
                            }
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            show: false,
                            offsetCenter: [0, '70%'],
                            //@ts-ignore
                            formatter: function(value) {
                                return '';
                            },
                            rich: {
                          
                                value: {
                                    fontSize: 0,
                                    fontWeight: 700,
                                    color: '#239CFB'
                                },
                                unit: {
                                 
                                    fontSize: 14,
                                    color: '#239CFB',
                                    padding: [0, 0, 6, 2]
                                },
                                line: {
                                    width: 100,
                                    height:3,
                                    shadowColor: 'rgba(229,242,252,0.90)',
                                    shadowBlur: 10,
                                    backgroundColor: 'rgba(229,242,252,0.90)',
                                    borderRadius: 43
                                }
                            }
                        },
                        axisTick: {
                            show:false,
                            length: 0,
                            lineStyle: {
                                color: '#999'
                            }
                        },
                        splitLine: {
                            show:false,
                            length: 15,
                            lineStyle: {
                                color: 'red'
                            }
                        },
                        axisLabel: {
                            show:false,
                            color: '#999'
                        },
                        pointer: {
                            // show:false,
                            width: 6,
                            length: 40
                        },
                        itemStyle: {
                            color: color,
                            shadowColor: 'rgba(0,138,255,0.45)',
                            shadowBlur: 10,
                            shadowOffsetX: 2,
                            shadowOffsetY: 2
                        },
                        markPoint: {
                            // symbol:'circle',
                            // symbolSize:15,
                            // itemStyle: {
                            //     color: "#fff"
                            // },
                            data: [{
                                x: "50%",
                                y: "50%",
                                symbol: 'circle',
                                symbolSize: 6,
                                itemStyle: {
                                    color: color
                                },
                            }, {
                                x: "50%",
                                y: "50%",
                                symbol: 'circle',
                                symbolSize: 15,
                                itemStyle: {
                                    color: "#fff"
                                },
                            }]
                        },
                        data: [{
                            value: value,
                            name: ''
                        }]
            
                    },
                    {
                        name: "",
                        type: 'gauge',
                        radius: '90%',
                        startAngle: 225,
                        endAngle: -45,
                        min: 0,
                        max: 100,
                        title: {
                            show: false
                        },
                        detail: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 8,
                                color: [
                                    [
                                        value / 100, color
                                    ],
                                    [
                                        1, 'rgba(225,225,225,0.4)'
                                    ]
                                ],
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false
                        },
                        pointer: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                color: '#54F200',
                            }
                        },
                        data: [{
                            value: value,
                            name: ''
                        }]
                    }
                ]
            
            }
            return option
        }

        let chart = echarts.init(document.getElementById('equipment-chart') as HTMLDivElement);
        let getTodayProcessData = new Request(API.GET_TODAY_PROCESS_DATA)
        getTodayProcessData.send().then(res => {
            let data: any = res
            let workRate = data.workRate
            this.setState({
                workRate,
            }, () => {
                //@ts-ignore
                chart.setOption(getChartOption(data.workRate), true)
            })
        })
        setInterval(() => {
            getTodayProcessData.send().then(res => {
                let data: any = res
                this.setState({
                    workRate: data.workRate.toFixed(0),
                }, () => {
                    //@ts-ignore
                    chart.setOption(getChartOption(data.workRate), true)
                })
            })
        }, 1000 * 30)
    }
    render() {
        return (
            <div className='equipment-content'>
                <ChartContainer id='equipment-chart' width={160} height={120} ></ChartContainer>
                <div className='card-tip'>
                    稼动率：<span className="card-num">{Math.floor(this.state.workRate)}</span> %
        </div>
            </div>
        )
    }
}