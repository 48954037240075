import React, { Component } from 'react'
import echarts from 'echarts';
import ChartContainer from '../../components/chartContainer/chartContainer'
interface Props {
  data: Array<any>,
  seriesName?: string
  id: string,
  unit?: string,
  lineColor?: string,
  areaStyle1?: string,
  areaStyle2?: string,
  maxData?: string,
  markLineData?: Array<any>
}
interface State {
  chartKey: Array<any>,
  chartValue: Array<any>
  id: string,
  seriesName: string,
  unit: string,
}
export default class BasicLineChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      unit: props.unit || '',
      seriesName: props.seriesName || '',
      chartKey: props.data.map((item: any) => item.key),
      chartValue: props.data.map((item: any) => item.value),
      id: props.id
    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    return nextProps
  }

  componentDidMount() {
    let option = {
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line'
        }
      },
      grid: {
        left: '2%',
        right: '0%',
        bottom: '0',
        top: '7%',
        containLabel: true
      },
      xAxis: [
        {

          splitLine: {
            show: true,
            lineStyle: {
              color: ['#444c59'],
              width: 1,
              type: 'solid'
            }
          },

          type: 'category',
          data: this.state.chartKey,
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: '14'
            }
          },
          axisLine: {
            lineStyle: {
              show: false,
              color: '#444c59'
            }
          }

        }
      ],
      dataZoom: [{
        show: true,
        type: 'inside'
      }],
      yAxis: [
        {
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#444c59'],
              width: 1,
              type: 'solid'
            }
          },

          offset: 0,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#fff',
              width: 0
            }

          },
          axisTick: {
            show: false
          },

          type: 'value',
          max: this.props.maxData || undefined
        }
      ],
      series: [
        {
          // showAllSymbol: false,
          smooth: true,
          name: this.state.seriesName,
          type: 'line',
          barWidth: '60%',
          data: this.state.chartValue,
          markLine: {
            silent: true,
            symbol: 'none',
            label: {
              show: false
            },
            lineStyle: {
              type: 'dashed',
              color: '#FC9800',
              width: 2
            },
            data: this.props.markLineData
          },
          itemStyle: {
            normal: {
              color: '#fff', //改变折线点的颜色
              lineStyle: {
                color: this.props.lineColor || '#3398DB' //改变折线颜色
              }
            }
          },
          areaStyle: {
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: this.props.areaStyle1 || "rgba(45,145,210)" // 0% 处的颜色
                }, {
                  offset: 0.7,
                  color: this.props.areaStyle2 || "rgba(45,145,210,0.1)" // 0% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              }
            }
          }
        }
      ]
    };
    let chart = echarts.init(document.getElementById(this.state.id) as HTMLDivElement);
    //@ts-ignore
    chart.setOption(option, true)
  }
  render() {
    return (
      <div>
        <ChartContainer id={this.state.id} width={350} height={180} />
      </div>
    )
  }
}