import React, { Component } from 'react'
import './blockBlank.scss'
interface Props {
  top?:number
}
interface State {
  top:string
}
export default class BlockBlank extends Component<Props,State> {
  constructor(props:Props) {
    super(props)
    this.state = {
      top:props.top+'px'
    }
  }
  render() {
    let style = {
      paddingTop:this.state.top
    }
    return (
      <div className='block-blank-box' style={style}>
        {this.props.children}
      </div>
    )
  }
}