import React, { Component } from 'react'
import './blockTitle.scss'
interface Props {
  name:string
}
interface State {
  name:string
}
export default class BlockTitle extends Component<Props,State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      name:props.name
    }
  }
  render() {
    return (
      <div className='block-title-box'>
        {this.state.name}
      </div>
    )
  }
}