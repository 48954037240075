import React, { Component } from 'react'
import echarts from 'echarts';
import ChartContainer from '../../../components/chartContainer/chartContainer'
import Request from '../../../api/request';
import API from '../../../api/api';
interface Props {
}
interface State {
  weekYield: [],
  allProcessData: 0,
  isLoading: boolean
}
export default class Production extends Component<Props, State> {
  private getWeekYield: Request
  private getAllProcessData: Request
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      weekYield: [],
      allProcessData: 0
    }
    this.getWeekYield = new Request(API.GET_HISTORY_PROCESS_BY_DAY_COUNT)
    this.getAllProcessData = new Request(API.GET_All_PROCESS_DATA)
  }

  componentDidMount() {
    let chart = echarts.init(document.getElementById('production-chart') as HTMLDivElement);

    this.getAllProcessData.send().then(res => {
      let data: any = res
      this.setState({
        allProcessData: data.processCount,
      })
      // this.props.changeLoadingProgress()
    })
    this.getWeekYield.send().then(res => {
      let data: any = res
      //@ts-ignore
      chart.setOption(getChartOption(data.map(item => item.value)))
    })

    setInterval(() => {
      this.getAllProcessData.send().then(res => {
        let data: any = res
        this.setState({
          allProcessData: data.processCount,
        })
      })

      this.getWeekYield.send().then(res => {
        //@ts-ignore
        chart.setOption(getChartOption(res.map(item => item.value)))
      })
    }, 1000 * 60)
    function getChartOption(data: Array<number>) {
      return {
        grid: {
          top: 10,
          bottom: 10,
          left: 30,
          right: 30
        },
        xAxis: {
          type: 'category',
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
          show: false
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [{
          data,
          type: 'bar',
          barWidth: '5',
          itemStyle: {

            normal: {
              barBorderRadius: [10, 10, 10, 10],
              color: '#1c94eb'
            }
          },
        }]
      }
    }

  }
  render() {
    return (
      <div className='production-content'>
        <ChartContainer id='production-chart' width={160} height={120} />
        <div className='card-tip'>
          累计：<span className="card-num">{this.state.allProcessData.toLocaleString()}</span> 双
        </div>
      </div>

    )
  }

}