import React, { Component } from 'react'
import echarts from 'echarts';
import 'echarts-liquidfill'
import ChartContainer from '../../../components/chartContainer/chartContainer'
import API from '../../../api/api';
import Request from '../../../api/request'

 interface Props {
  changeLoadingProgress?:Function
 }

export default class Quality extends Component<Props> {

  state = {
    yieldRate: 0
  }
  componentDidMount() {
    let chart = echarts.init(document.getElementById('quality-chart') as HTMLDivElement);
    function getChartOption(yieldRate: number) {
      let seriesData = yieldRate
      let colorArr = ['#0C95FF', '#1362EB']
      let data = [{
        value: seriesData,
        name: '已完成',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [{
              offset: 0,
              color: colorArr[0]
            },
            {
              offset: 1,
              color: colorArr[1]
            }
            ]
          )
        }
      },

      {
        value: 100 - seriesData,
        name: '进行中',
        itemStyle: {
          color: '#111F42'
        }
      }
      ]
      let Filldata = data[0].value / (data[0].value + data[1].value)
      return {

        tooltip: {
          trigger: 'item',
          //@ts-ignore
          formatter: function (res) {

            if (res.componentSubType === 'liquidFill') {
              return ''
            } else {
              return '' + res.name + '：' + res.data.value + '项';
            }
          }
        },
        title: {
          text: (Filldata * 100).toFixed(0) + '%',
          left: 'center',
          top: 'center',
          textStyle: {
            fontSize: 28,
            fontWeight: 'normal',
            color: '#BECBE3'
          }
        },

        series: [{
          type: 'liquidFill',
          data: [{
            value: Filldata,
          }, {
            value: Filldata,
          }],
          backgroundStyle: {
            color: "transparent"
          },
          color: ['rgba(68,165,255, 0.3)', 'rgba(68,165,255, 0.5)'],
          radius: '80%',
          center: ['50%', '50%'],
          label: {
            normal: {
              formatter: '',
            }
          },
          outline: {
            show: false
          }
        },
        {
          type: 'pie',
          radius: ['80%', '85%'],
          label: {
            show: false,
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          hoverAnimation: false,
          data: data
        }
        ]
      }

    }

    
    //@ts-ignore
    let getHistoryYieldRate = new Request(API.GET_TODAY_PROCESS_DATA)
    getHistoryYieldRate.send().then(res => {
      let response: any = res
      this.setState({
        yieldRate: response.yieldRate
      })
    
      // this.props.changeLoadingProgress()
        //@ts-ignore
      chart.setOption(getChartOption(response.yieldRate), true)
    })
    setInterval(() => {
      getHistoryYieldRate.send().then(res => {
        let response: any = res
        this.setState({
          yieldRate: response.yieldRate
        })
        //@ts-ignore
        chart.setOption(getChartOption(response.yieldRate), true)
      })
    }, 1000*30)
  }
  render() {
    return (
      <div className='quality-content'>
        <ChartContainer id='quality-chart' width={160} height={120} ></ChartContainer>
        <div className='card-tip'>
          直通率：<span className="card-num">{this.state.yieldRate}</span> %
        </div>
      </div>
    )
  }
}