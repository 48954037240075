import React, { Component } from 'react'
interface Props {
  id:string,
  width?:number,
  height?:number
}
export default class ChartContainer extends Component<Props> {
  render() {
    // let winWidth = window.document.documentElement.clientWidth
    // if (winWidth > 640) winWidth = 640
  
    return (
      <div className="chart-container" id={this.props.id} style={{width:this.props.width+'px',height:this.props.height+'px'}}></div>
    )
  }
}