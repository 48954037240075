import React, { Component } from 'react'
import './miniCard.scss'
import classnames from 'classnames'
import Icon from '../icon/icon'
import _ from 'lodash'
interface Props {
  unit: string,
  value: number,
  icon: string
  desc: string,
  size?: string
}
interface State {
  unit: string,
  value: number | string,
  icon: string,
  desc: string,
  size: string
}
export default class MiniCard extends Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      size: props.size || 'sm',
      unit: this.handleUnit(this.props.unit) || '',
      value: this.handleValue(this.props.value) || 0,
      icon: this.props.icon || '',
      desc: this.props.desc || ''
    }
  }

  handleValue(value: number) {
    if (value >= 100000) {
      return Math.floor(value / 1000) / 10
    }
    return _.round(value, 1).toLocaleString()
  }
  handleUnit(unit: string) {
    if (this.props.value >= 100000) {
      return '万' + this.props.unit
    }
    return unit
  }
  //千分位符
  addThousandSeparator(num: number) {
    let arr = num.toString().split('')
    let allProcessStr = ''
    arr.forEach((item, index) => {
      allProcessStr += item
      if ((index + 1) % 3 === 0 && index !== arr.length - 1) {
        allProcessStr += ','
      }
    })
    return allProcessStr
  }
  render() {
    return (
      <div className={classnames({
        'mini-card-box': true,
        'sm': this.state.size === 'sm',
        'lg': this.state.size === 'lg'
      })}>
        <div className='icon-circle-content content-bg-light'>
          <Icon size={20} url={this.state.icon} />
        </div>
        <div className='text-content'>
          <div className='text-content-top'>
            <div className='value' ><span className={classnames({
              'card-num': true,
              'card-badNum': (this.props.desc === '缺陷次数' || this.props.desc.match('能耗')) && this.props.value !== 0,
            })}>{this.state.value}</span></div>
            <div className='unit'>{this.state.unit}</div>
          </div>
          <div className='text-content-bottom'>
            {this.state.desc}
          </div>
        </div>
      </div>
    )
  }
}