import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../pages/home/home'
import Production from '../pages/production/production'
import Environment from '../pages/environment/environment'
// import App from '../App'
import Quality from '@/pages/quality/quality';
import Equipment from '@/pages/equipment/equipment';
import SingleStations from '@/pages/singleStations/singleStations';

class Routes extends Component {
  render() {
    return (

      <Router>
        <Switch>
          <Route exact path='/SingleStations/:id' > 
            <SingleStations />
          </Route>
  
          <Route exact path='/equipment'>
            <Equipment />
          </Route>
          <Route exact path='/production'>
            <Production />
          </Route>
          <Route exact path='/environment'>
            <Environment />
          </Route>
          <Route exact path='/quality'>
            <Quality />
          </Route>
          <Route exact path='/' >
            <Home />
          </Route>
          {/* <Route exact path='/' component={App} /> */}
        </Switch>
      </Router>
    )
  }
}

export default Routes


