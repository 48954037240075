import React, { Component } from 'react'
import classnames from 'classnames'
import './timePeriodSelector.scss'
import Icon from '../icon/icon'
import moment from 'moment'
interface Props {
  changeTimePeriod: Function
}
interface State {
  tabs: Array<any>,
  popoverItems: Array<any>,
  isHidePopover: Boolean,
  currentPopoverItemName: string,
  currentPopoverItemActive: Boolean
}
export default class TimePeriodSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentPopoverItemName: '今年(按周)',
      currentPopoverItemActive: false,
      popoverItems: [{
        name: '今年(按周)',
        type: 3
      }, {
        name: '今年(按月)',
        type: 4
      },
      {
        name: '今年(按季度)',
        type: 5
      },
      {
        name: '累积',
        type: 6
      }
      ],
      isHidePopover: true,
      tabs: [
        {
          name: '今日',
          active: true,
          type: 0
        },
        {
          name: '本周',
          active: false,
          type: 1
        },
        {
          name: '本月',
          active: false,
          type: 2
        }
      ]
    }
  }
  render() {
    let tabs = this.state.tabs.map((item, idx) =>
      <div
        onClick={this.changeTab.bind(this, item.type)}
        key={idx}
        className={classnames({
          'selector-item': true,
          'active': item.active
        })}>{item.name}</div>)
    let popoverItems = this.state.popoverItems.map((item, idx) => <div className='popover-item' onClick={this.selectPopoverItem.bind(this, item.type)} key={idx}>{item.name}</div>)
    return (
      <div className='time-period-selector-box'>
        {tabs}
        <div className={'selector-item ' + classnames({
          'active': this.state.currentPopoverItemActive
        })} onClick={this.showPopover.bind(this)}>
          <span>{this.state.currentPopoverItemName}</span>
          <Icon size={14} rotate={-90} url={require('../../assets/images/arrow.png')} />
          <div className={'content-bg '+classnames({
            'popover': true,
            'hide': this.state.isHidePopover
          })}>
            <div className='popover-arrow'></div>
            {popoverItems}

          </div>
          <div className={classnames({
            'mask': true,
            'hide': this.state.isHidePopover
          })}></div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.changeTimePeriod(this.getStartDateAndEndDate(0), 0)
  }
  /**
   * 切换 tab
   * @param periodType 
   */
  changeTab(periodType: number) {
    let tabs = this.state.tabs
    tabs.forEach(item => {
      item.active = false
      item.type === periodType && (item.active = true)
    })
    this.setState({ tabs, currentPopoverItemActive: false, isHidePopover: true })
    this.props.changeTimePeriod(this.getStartDateAndEndDate(periodType), periodType)



  }
  /**
   * 展示菜单
   */
  showPopover() {
    this.setState({
      isHidePopover: !this.state.isHidePopover
    })
  }
  /**
   * 选择菜单里面的选项
   */
  selectPopoverItem(periodType: number) {
    let tabs = this.state.tabs
    let popoverItems = this.state.popoverItems
    let currentPopoverItemName = ''
    tabs.forEach(item => { item.active = false })
    popoverItems.forEach(item => {
      if (periodType === item.type) {
        currentPopoverItemName = item.name
      }
    })

    this.setState({ tabs, currentPopoverItemName, currentPopoverItemActive: true })
    this.props.changeTimePeriod(this.getStartDateAndEndDate(periodType), periodType)

  }

  getStartDateAndEndDate(type: number) {
    let endDate: string = ''
    let startDate: string = ''
    switch (type) {
      case 0:
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().add('days', 1).format('YYYY-MM-DD');
        break;
      case 1:
        startDate = moment().startOf('week').add('days',1).format('YYYY-MM-DD')
        endDate = moment().endOf('weeks').add('days', 2).format('YYYY-MM-DD')
        break;
      case 2:
        startDate = moment().startOf('month').format('YYYY-MM-DD')
        endDate = moment().endOf('month').add('days', 1).format('YYYY-MM-DD')
        break;
      case 3:
        startDate = moment().startOf('year').format('YYYY-MM-DD')
        endDate = moment().add('years', 1).startOf('year').format('YYYY-MM-DD')
        break;
      case 4:
        startDate = moment().startOf('year').format('YYYY-MM-DD')
        endDate = moment().add('years', 1).startOf('year').format('YYYY-MM-DD')
        break;
      case 5:
        startDate = moment().startOf('year').format('YYYY-MM-DD')
        endDate = moment().add('years', 1).startOf('year').format('YYYY-MM-DD')
        break;
      case 6:
        startDate = moment().add('years',-1).startOf('year').format('YYYY-MM-DD')
        endDate = moment().add('years', 1).startOf('year').format('YYYY-MM-DD')
        break;
      default:
        break;
    }
    return [startDate, endDate]
  }
}