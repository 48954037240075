import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Router from './router/index';
ReactDOM.render(<Router />, document.getElementById('root'))

document.getElementById('loading-content')!.style.display='none'
document.getElementById('root')!.style.display='block'

serviceWorker.unregister();
