import React, { Component } from 'react'
import classnames from 'classnames'
import './cellGroup.scss'
import Icon from '../icon/icon'
interface Props {
  list: Array<any>
}

export default class CellGroup extends Component<Props> {
  // constructor(props: Props) {
  //   super(props);
  // }

  render() {
    // console.log('list', this.props.list);
    return (
      <div className='cell-group-box content-bg'>
        {
          this.props.list.map((item: any, idx: number) => {
            return (
              <div className={classnames({
                'cell-group-item': true,
                'error': item.error,
                'normal': true
              })} key={idx}>
                <div className='cell-group-item-icon'>
                  <Icon url={item.error ? item.errorIcon : item.icon} size={14} />
                </div>
                <div className='cell-group-item-title'>{item.title}</div>
                <div className="cell-group-item-status"><span className={classnames({
                  'card-num': true,
                  'card-badNum': idx === 1
                })}>{item.status.value1}</span> {item.status.unit1} <span className={classnames({
                  'card-num': true,
                  'card-badNum': idx === 1 && item.status.value2 !== 0
                })}
                >{item.status.value2}</span> {item.status.unit2}</div>
                {/* <div className="cell-group-item-status">{item.status}</div> */}
              </div>
            )
          })
        }
        {this.props.children}
      </div>
    )
  }
}