import React from 'react';
import echarts from 'echarts';
// import './DetailsHeapBarChart.scss';
// import classNames from 'classnames';

interface Props {
    theid: any,
    // boundaryGap: any,
    dataX: any,
    markLineData: any,
    dataY1: any,
    dataY2: any
    size: any,
    boundaryGap: any,
}
interface State {

}

class DetailsHeapBarChart extends React.Component<Props, State> {

    generatingCharts() {
        if (document.getElementById(this.props.theid) != null) {

            let myChart = echarts.init(document.getElementById(this.props.theid) as HTMLDivElement);

            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#444c59'
                        }
                    }
                },
                legend: {
                    data: ['产量'],
                    show: false
                },
                toolbox: {
                    feature: {
                        // saveAsImage: {}
                    }
                },
                grid: {
                    left: '2%',
                    right: '0%',
                    bottom: '0',
                    top: '7%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.props.dataX,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#444c59'
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 14
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#444c59',
                                opacity: 0.5
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        boundaryGap: this.props.boundaryGap,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#444c59'
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 14
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#444c59',
                                // opacity: 0.5
                            }
                        },
                        axisTick: {
                            show: false
                        },
                    },
                ],
                dataZoom: [{
                    type: "inside"         //详细配置可见echarts官网
                }],
                series: [
                    {
                        name: '稼动时长',
                        type: 'bar',
                        stack: '总量',
                        markLine: {
                            silent: true,
                            symbol: 'none',
                            label: {
                                show: false
                            },
                            lineStyle: {
                                type: 'dashed',
                                color: '#E81F1F',
                                width: 2,
                            },
                            data: this.props.markLineData
                        },
                        label: {
                            show: true,
                            position: 'insideRight'
                        },
                        data: this.props.dataY1,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,      //开启显示
                                    position: 'top', //在上方显示
                                    textStyle: {     //数值样式
                                        color: '#fff',
                                        fontSize: 12
                                    }
                                },
                                color: '#3680f8',
                            }
                        }
                    },
                    {
                        name: '待机时长',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'insideRight'
                        },
                        data: this.props.dataY2,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,      //开启显示
                                    position: 'top', //在上方显示
                                    textStyle: {     //数值样式
                                        color: '#fff',
                                        fontSize: 12
                                    }
                                },
                                color: '#FC9800'
                            }
                        }
                    }
                ]
            });
        }
    }

    render() {
        this.generatingCharts();
        return (
            <div id={this.props.theid} style={{ width: this.props.size.width, height: this.props.size.height }}></div>
        )
    }
}

export default DetailsHeapBarChart;