import React, { Component } from 'react'
import echarts from 'echarts';
import ChartContainer from '../../components/chartContainer/chartContainer'
interface Props {
  data: Array<any>
}
interface State {
  chartData:Array<any>
}
export default class Sho extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      chartData:props.data.map((item:any)=>{return {name:item.key,value:item.value}})
    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    return nextProps
  }

  componentDidMount() {
    console.log(this.state.chartData)
   let option = {
      series: [{
          breadcrumb: { show: false},
          type: 'treemap',
          data: this.state.chartData.length === 0? [{name:'暂无数据',value:1}]:this.state.chartData
      }]
  };
  

    let chart = echarts.init(document.getElementById('shoe-type-chart') as HTMLDivElement);
    //@ts-ignore
    chart.setOption(option, true)
  }
  render() {
    return (
      <div>
        <ChartContainer id='shoe-type-chart' width={350} height={180} />
      </div>
    )
  }
}