import React, { Component, Fragment } from 'react'
import PublicHeader from '../../components/header/header'
import TimePeriodSelector from '@/components/timePeriodSelector/timePeriodSelector'
import { Flex } from 'antd-mobile'
import MiniCard from '@/components/miniCard/miniCard'
import BlockBlank from '@/components/blockBlank/blockBlank'
import Card from '@/components/card/card'
import CellGroup from '@/components/cellGroup/cellGroup'
import NotFound from '@/components/notFound/notFound'
import { withRouter } from 'react-router-dom'
import './singleStations.scss'
import BasicLineChart from '@/components/basicLineChart/basicLineChart'
import BlockTitle from '@/components/blockTitle/blockTitle'
import Request from '@/api/request'
import API from '@/api/api'
import Loading from '@/components/loading/loading'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
// import VideoPlayer from './videoPlayer'
//@ts-ignore
// import EZUIKit from 'ezuikit-js';
// import { url } from 'inspector'
import UseUikit from '@/components/useUikit/UseUikit';
const idToStationMap: any = {
  1: {
    name: '工位1实况',
    // videoUrl: 'https://hls01open.ys7.com/openlive/b5f176934ae14b4f9c8c6f2aa08a94e7.m3u8',
    url: 'ezopen://open.ys7.com/E45418089/1.live',
  },

  2: {
    name: '工位2实况',
    // videoUrl: 'https://hls01open.ys7.com/openlive/4ef6160724374a1386444f5fad34a6c0.m3u8'
    url: 'ezopen://open.ys7.com/E45418124/1.live',
  },
  3: {
    name: '工位3实况',
    // videoUrl: 'https://hls01open.ys7.com/openlive/c1482396e6ff40538b7209fff389ec15.m3u8'
    url: 'ezopen://open.ys7.com/F36549367/1.live',
  },
  4: {
    name: '工位4实况',
    // videoUrl: 'https://hls01open.ys7.com/openlive/f4dd257036354291ab2e1b44f92fecc9.m3u8'
    url: 'ezopen://open.ys7.com/F36549326/1.live',
  },
  5: {
    name: '工位5实况',
    // videoUrl: 'https://hls01open.ys7.com/openlive/253839a82d6f480e9e0256e4c810a643.m3u8',
    url: 'ezopen://open.ys7.com/F30053905/1.live',
  },
  6: {
    name: '产线实况',
    // videoUrl: 'http://hls01open.ys7.com/openlive/cfe42a923f394c7b908c657fceb1817c.m3u8',
    url: 'ezopen://open.ys7.com/F28852729/1.live',
  },
  7: {
    name: '视觉品质检测实况',
    // videoUrl: 'https://hls01open.ys7.com/openlive/199cef5b7a05472ca539b3895f5933dd.m3u8',
    url: 'ezopen://open.ys7.com/D47932065/1.live',
  },
}
interface Props { }
interface State {
  testSuccess: boolean,
  isLoading: boolean
  miniCardGroup: Array<any>,
  cellList: Array<any>
  stationType: string
  throughChart: Array<any>,
  testImage: string,
  pageLoading: boolean,
  standbyTime: number
}

class SingleStations extends Component<Props, State> {
  private getProductionThroughData: Request
  private get2DTesting: Request
  time1!: NodeJS.Timeout
  constructor(props: Props) {
    super(props)
    this.getProductionThroughData = new Request(API.GET_PRODUCTION_THROUGH_DATA)
    this.get2DTesting = new Request(API.GET_2D_TESTING)
    this.state = {
      standbyTime: 0,
      pageLoading: true,
      testImage: '',
      testSuccess: false,
      throughChart: [],
      isLoading: false,
      cellList: [{
        icon: require('../../assets/images/ggx.png'),
        title: '检测鞋型',
        status: { unit2: '--' }
      }, {
        icon: require('../../assets/images/chima.png'),
        title: '尺码',
        status: { unit2: '--' }
      }],
      stationType: '1',
      miniCardGroup: [
        {
          unit: '%',
          desc: '直通率',
          value: '0',
          icon: require('../../assets/images/ztlv.png'),
          size: 'lg'
        },
        {
          unit: '次',
          desc: '加工成功',
          value: '0',
          icon: require('../../assets/images/liangpinglv.png'),
        },
        {
          unit: '次',
          desc: '缺陷次数',
          value: '0',
          icon: require('../../assets/images/quexian.png'),
        }
      ]
    }
  }
  changeTimePeriod(times: Array<any>, type: number) {
    this.setState({ isLoading: true })
    this.getProductionThroughData.send({
      params: {
        beginTime: times[0],
        endTime: times[1],
        timeRangeType: type,
        robotCode: this.state.stationType
      }
    }).then(response => {
      let res: any = response
      let miniCardGroup = this.state.miniCardGroup
      miniCardGroup[0].value = Math.floor(res.throughRate * 100)
      miniCardGroup[1].value = res.goodProductionCount
      miniCardGroup[2].value = res.defectCount
      let throughChart = this.handleChartValue(res.chartModels)
      this.setState({ miniCardGroup, isLoading: false, throughChart })
    })
  }

  handleChartValue(data: Array<any>) {
    let len = data.length;
    let handleValue = data;
    for (let i = 0; i < len; i++) {
      handleValue[i].value = _.round(handleValue[i].value, 1)
    }
    return handleValue;
  }

  componentWillMount() {
    this.setState({
      //@ts-ignore
      stationType: this.props.match.params.id,
      //@ts-ignore
      pageLoading: this.props.match.params.id !== '6' && this.props.match.params.id !== '7'
    })


  }
  componentDidMount() {
    document.getElementById("root")!.scrollIntoView();
    if (this.state.stationType !== '6' && this.state.stationType !== '7') {
      this.get2dTestingData()
      this.time1 = setInterval(() => {
        this.get2dTestingData()
      }, 2000)
    }
  }

  componentWillUnmount() {
    if (this.time1 != null) {
      clearInterval(this.time1);
    }
  }

  get2dTestingData() {
    // console.log('stationType', this.state.stationType);
    new Request(API.GET_2D_TESTING + Number(this.state.stationType)).send().then(response => {
      let res: any = response
      // console.log('res', res);
      let testSuccess = res.success
      let testImage =
        (window.location.hostname.substring(0, 3) === '192' ?
          'http://192.168.1.3:11003/'
          :
          'http://api.a2.zs-tek.com/') + res.imageUrl;
      let standbyTime = moment(new Date()).diff(moment(res.addDate), 'minute')
      let cellList = [
        {
          icon: require('../../assets/images/ggx.png'),
          title: '检测鞋型',
          status: { unit2: (res.shoeTypeName + (res.shoeLeftAndRight ? ' 右脚' : ' 左脚')) }
          // res.shoeTypeName + (res.shoeLeftAndRight ? ' 右脚' : ' 左脚')
        },
        {
          icon: require('../../assets/images/chima.png'),
          title: '尺码',
          status: { value2: res.shoeSize }
          // res.shoeSize
        }

      ]
      let errorList = res.testingNumericalValue.split('//')

      let result: Array<any> = []
      errorList.forEach((item: any) => {
        let itemArr: Array<any> = item.split('：')
        result.push({
          icon: require('../../assets/images/err.png'),
          title: itemArr[0],
          status: itemArr[1]
        })
      })
      //原代码: standbyTime < 60 一分钟之内刷新的才显示
      if (standbyTime) {
        this.setState({
          cellList: [...cellList, ...result],
        })
      }
      this.setState(() => {
        return {
          standbyTime,
          testSuccess,
          testImage,
          pageLoading: false
        }
      })
    })
  }

  render() {
    return (
      <div className='wrap'>
        {
          idToStationMap[this.state.stationType] ?
            <div className='page-content'>
              {
                this.state.pageLoading ? <Loading /> :
                  <Fragment>
                    <PublicHeader name={idToStationMap[this.state.stationType].name} />
                    <BlockBlank>
                      {this.state.stationType !== '6' && this.state.stationType !== '7' ? <BlockTitle name='实时品质检测' /> : ''}
                      {this.state.stationType !== '6' && this.state.stationType !== '7' ? <CellGroup list={this.state.cellList}>
                        {
                          //原代码 this.state.standbyTime > 60 大于60秒不显示
                          !this.state.standbyTime ? null :
                            <div className='testing-content'>
                              {/* <div className="testing-content-left">
                                <img src={this.state.testImage} alt='' className='test-image' />
                              </div>
                              <div className={classnames({
                                'testing-content-right': true,
                                'not-good': !this.state.testSuccess
                              })}>
                                {this.state.testSuccess ? 'ok' : 'ng'}
                              </div> */}
                              <img src={`${this.state.testImage}`} alt="" className='test-image' />
                              <div className={classnames({
                                'testing-content-right': true,
                                'not-good': !this.state.testSuccess
                              })}>{this.state.testSuccess ? 'OK' : 'NG'}</div>
                            </div>
                        }
                      </CellGroup> : ''}
                      <BlockTitle name='视频直播' />
                      {/* <VideoPlayer src={idToStationMap[this.state.stationType].videoUrl} /> */}
                      {/* <div id="video-container" style={{ width: '200px', height: '100px' }}></div> */}
                      <UseUikit data={idToStationMap[this.state.stationType]} />
                      <div className={classnames({
                        'only-video': this.state.stationType === '6' || this.state.stationType === '7',
                      })}></div>
                      {this.state.stationType !== '6' && this.state.stationType !== '7' ? <BlockTitle name='生产质量' /> : ''}
                    </BlockBlank>
                    {this.state.stationType !== '6' && this.state.stationType !== '7' ? <TimePeriodSelector changeTimePeriod={this.changeTimePeriod.bind(this)} /> : ''}
                    {
                      this.state.stationType !== '6' && this.state.stationType !== '7' ? this.state.isLoading ? <Loading /> :
                        <BlockBlank>
                          <Flex wrap='wrap' justify='between'>
                            {
                              this.state.miniCardGroup.map((item: any, idx: number) => {
                                return <MiniCard unit={item.unit} icon={item.icon} desc={item.desc} value={item.value} size={item.size} key={idx} />
                              })
                            }
                          </Flex>
                          <Card title='直通率(%)' icon={require('../../assets/images/zhitonglv.png')} showRightArrow='false' width={350}>
                            <BasicLineChart seriesName='直通率' data={this.state.throughChart} id='through-chart'></BasicLineChart>
                          </Card>
                        </BlockBlank>
                        : ''
                    }
                  </Fragment>
              }
            </div> :

            <NotFound />
        }
      </div>


    )
  }
}

//@ts-ignore
export default withRouter(SingleStations)